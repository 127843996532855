.root {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.header,
.gridRow {
  display: grid;
  grid-template-columns: 150px 1fr;
  row-gap: 48px;
  align-items: center;
  column-gap: 24px;
  padding-right: 20px;
  text-overflow: ellipsis;
}

.header {
  margin-bottom: 46px;
}

.gridRow {
  margin-bottom: 36px;
}

.grid {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 40px;
}

.gridHeaderItem {
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #aaaaaa;
}

.fieldValueContainer {
  display: flex;
  align-items: flex-start;
}

.fieldName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.fieldValue {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  max-width: 100%;
  text-overflow: ellipsis;
}

.mobileName {
  font-weight: 500;
  margin-bottom: 8px;
}

@media screen and (max-width: 600px) {
  .root {
    height: auto;
  }
  .grid {
    max-height: none;
  }
  .header,
  .gridRow {
    gap: 16px;
    grid-template-columns: 1fr 100px;
    padding-right: 0;
  }
}
