.root {
  margin-bottom: 96px;
}

.content {
  display: grid;
  grid-template-columns: 35% minmax(400px, 40%);
  justify-content: center;
  gap: 120px;
}

.return {
  padding: 48px 0 136px;
}

.imageContainer {
  text-align: right;
  display: inline-block;
}

.nextPageBtn {
  margin-top: 25px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
}

.actionButtons {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 56px;
  position: relative;
}

.select {
  margin-bottom: 24px;
}

.recognizeHint {
  color: var(--black);
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  left: 150px;
  top: 6px;
  text-align: left;
  background-color: var(--white);
  z-index: 3;
}

.closePopup {
  position: absolute;
  right: 40px;
  top: 40px;
}

.popup {
  padding-top: 92px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--white);
  display: flex;
  justify-content: center;
  padding-top: calc(48px + 66px);
  overflow-y: scroll;
  z-index: 100;
}

@media screen and (max-width: 600px) {
  .content {
    display: flex;
    flex-direction: column;
  }

  .imageContainer {
    margin-bottom: 24px;
    max-height: 700px;
    overflow-y: scroll;
  }

  .return {
    padding: 48px 0;
  }

  .csv {
    display: none;
  }

  .actionButtons {
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* grid-template-rows: 1fr 1fr; */
    gap: 24px;
  }

  .formControlLabel span {
    font-size: 14px;
    line-height: 19px;
  }

  .imageContainer {
    height: auto !important;
    text-align: right;
    display: block;
    width: 100%;
  }
}
